/**
 * methods
 *
 * @summary   主入口
 * @desc      全局方法
 */

'use strict'
/* eslint-disable */

// -----------------------------------------------------------------------------
// 原生JS实现addClass, removeClass, toggleClass函数
// hasClass
const hasClass = function (obj, cls) {
  let reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
  if (reg.test(obj.className)) {
    return true
  } else {
    return false
  }
}
// addClass
const addClass = function (obj, cls) {
  if (!hasClass(obj, cls)) {
    obj.className += ' ' + cls
  }
}
// removeClass
const removeClass = function (obj, cls) {
  if (hasClass(obj, cls)) {
    let reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    obj.className = obj.className.replace(reg, '')
  } else {
    // console.log('This className is not exist')
  }
}
// toggleClass
const toggleClass = function (obj, cls) {
  if (hasClass(obj, cls)) {
    removeClass(obj, cls)
  } else {
    addClass(obj, cls)
  }
}

// -----------------------------------------------------------------------------
// 生成uuid
const createUUID = function (len, radix) {
  // len长度，radix进制
  let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split(
    ''
  )
  let uuid = []
  radix = radix || chars.length

  if (len) {
    // Compact form
    for (let i = 0; i < len; i++) {
      uuid[i] = chars[0 | (Math.random() * radix)]
    }
  } else {
    // rfc4122, version 4 form
    let r
    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
    uuid[14] = '4'
    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16)
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r]
      }
    }
  }

  return uuid.join('')
}

// -----------------------------------------------------------------------------
// 将类数组转换成数组
const nodelistToArray = function (nodelist) {
  let makeArray = function (obj) {
    return Array.prototype.slice.call(obj, 0)
  }
  try {
    Array.prototype.slice.call(document.documentElement.childNodes, 0)[0]
      .nodeType
  } catch (e) {
    makeArray = function (obj) {
      let res = []
      for (let i = 0, len = obj.length; i < len; i++) {
        res.push(obj[i])
      }
      return res
    }
  }
  let arr = makeArray(nodelist)
  return arr
}

// -----------------------------------------------------------------------------
// handle localStorage
// 查询localStorage所有的key
const showLocalStorage = function () {
  let len = localStorage.length
  console.log('localStorage的长度为', len)
  for (let i = 0; i < len; i++) {
    console.log(
      '第',
      i,
      '条item：键为[',
      localStorage.key(i),
      ']，值为[',
      localStorage.getItem(localStorage.key(i)),
      ']'
    )
  }
}
// 清除localStorage指定的key
const removeLocalStorageItems = function (arr) {
  arr.map((item) => {
    localStorage.removeItem(item)
  })
}
// 清除localStorage，但保留部分key
const removeLocalStorageItemsExcept = function (arr) {
  let saveArr = []
  arr.map((item) => {
    saveArr.push(localStorage.getItem(item))
  })
  localStorage.clear()
  saveArr.map((item, i) => {
    localStorage.setItem(arr[i], item)
  })
}

// -----------------------------------------------------------------------------
// 获取地址栏参数方法
const getLocalHrefParam = function (paramName) {
  let reg = new RegExp('(^|&)' + paramName + '=([^&]*)(&|$)')
  let r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return unescape(r[2])
  } else {
    return null
  }
}

/**
 * @desc    从给定url获取参数方法
 *          传入一个参数,返回全部参数的对象;传入两个参数,返回改参数的参数值
 * @param   {String} url
 * @param   {String} paramName
 * @return  {Object|String}
 */
const getUrlParams = function (url, paramName) {
  if (!url) {
    console.log(`Function "getUrlParams" requires arguments "url"`)
    return
  }
  if (url.split('?').length <= 1) {
    console.log(`This url doesn't have any parameters`)
    return
  }
  let urlParamsArr = url.split('?')[1].split('&')
  let urlParams = {}
  urlParamsArr.map((item, i) => {
    let key = item.split('=')[0]
    let value = item.split('=')[1]
    urlParams[key] = value
  })
  if (paramName) {
    return urlParams[paramName]
  } else {
    return urlParams
  }
}

// -----------------------------------------------------------------------------
// 格式化柱状图表数据
const formatterColChartData = function (data, keyName, valName) {
  let keyCache = []
  let returnData = {
    key: [],
    val: []
  }
  for (let keyName in data[0]) {
    keyCache.push(keyName)
  }
  console.log('keyCache', keyCache)
  // console.log('data', data)

  for (let i = 0; i < data.length; i++) {
    // console.log('keyCache[0]', keyCache[0])
    returnData.key.push(data[i][keyCache[0]])
    returnData.val.push(data[i][keyCache[1]])
  }
  console.log('返回数据：', returnData)
  return returnData
}

// -----------------------------------------------------------------------------
// 格式化饼状图表数据
const formatterPieChartData = function (data) {
  let keyCache = []
  let returnData = {
    key: [],
    val: [],
    data: []
  }
  for (let key in data[0]) {
    keyCache.push(key)
  }
  // console.log('keyCache', keyCache)
  // console.log('data', data)

  for (let i = 0; i < data.length; i++) {
    // console.log('keyCache[0]', keyCache[0])
    returnData.key.push(data[i][keyCache[0]])
    returnData.val.push(data[i][keyCache[1]])
  }
  // console.log('数据：', returnData)
  return returnData
}

// -----------------------------------------------------------------------------
// 原生js实现ajax方法
const ajax = function () {
  let ajaxData = {
    type: arguments[0].type || 'GET',
    url: arguments[0].url || '',
    async: arguments[0].async === false ? false : true,
    data: arguments[0].data || null,
    dataType: arguments[0].dataType || 'text',
    contentType: arguments[0].contentType || 'application/json;charset=UTF-8',
    headers: arguments[0].headers || {},
    beforeSend: arguments[0].beforeSend || function () {},
    success: arguments[0].success || function () {},
    error: arguments[0].error || function () {}
  }

  ajaxData.beforeSend()

  let xhr = createxmlHttpRequest()
  switch (ajaxData.type.toLocaleUpperCase()) {
    case 'GET':
      xhr.responseType = ajaxData.dataType
      let params = ''
      for (let i in ajaxData.data) {
        params += i + '=' + ajaxData.data[i] + '&'
      }
      params = params.slice(0, params.length - 1)
      let url = ajaxData.url + '?' + params
      xhr.open(ajaxData.type, url, ajaxData.async)
      xhr.setRequestHeader('Content-Type', ajaxData.contentType)
      for (let key in ajaxData.headers) {
        xhr.setRequestHeader(key, ajaxData.headers[key])
      }
      xhr.send()
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            ajaxData.success(xhr.response)
          } else {
            ajaxData.error()
          }
        }
      }
      break
    case 'POST':
      xhr.responseType = ajaxData.dataType
      xhr.open(ajaxData.type, ajaxData.url, ajaxData.async)
      xhr.setRequestHeader('Content-Type', ajaxData.contentType)
      for (let key in ajaxData.headers) {
        xhr.setRequestHeader(key, ajaxData.headers[key])
      }
      xhr.send(convertData(ajaxData.data))
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            ajaxData.success(xhr.response)
          } else {
            ajaxData.error()
          }
        }
      }
      break
  }
}

const createxmlHttpRequest = function () {
  if (window.ActiveXObject) {
    return new ActiveXObject('Microsoft.XMLHTTP')
  } else if (window.XMLHttpRequest) {
    return new XMLHttpRequest()
  }
}

const convertData = function (data) {
  if (typeof data === 'object') {
    return JSON.stringify(data)
  } else {
    return data
  }
}


// -----------------------------------------------------------------------------
/**
 * @desc    将驼峰格式转换成大写下划线格式
 * @param   {String}
 * @return  {String}
 */
const camelCaseToUpperCaseUnderline = function (str) {
  return str.replace(/([A-Z])/g, '_$1').toUpperCase()
}

// -----------------------------------------------------------------------------
/**
 * @desc 获取传入的日期前N天或后N日期(N = day)
 * @param {number} day 多少天
 * @param {number} type 1： 前； 2： 后
 * @param {Date} date：传入的日期
 */
const getBeforeOrAfterDate = function (day, type, date) {
  let zdate
  if (date === null || date === undefined) {
    zdate = new Date()
  } else {
    zdate = date
  }
  let edate
  if (type === 1) {
    edate = new Date(zdate.getTime() - day * 24 * 60 * 60 * 1000)
  } else {
    edate = new Date(zdate.getTime() + day * 24 * 60 * 60 * 1000)
  }
  return edate
}

/**
 * @desc 获取上周的起始和结束时间
 */
const getLastWeekArray = function (isWorkDay) {
  let returnArr = []
  let date = new Date() //当前时间
  let week = date.getDay() //获取今天星期几
  let monday = getBeforeOrAfterDate(week + 6, 1, date) //获取上周星期一
  let lastDay
  if (isWorkDay) {
    lastDay = getBeforeOrAfterDate(week + 1, 1, date) // 获取上周星期天
  } else {
    lastDay = getBeforeOrAfterDate(week, 1, date) // 默认获取上周星期天
  }
  //起始时间的年月日
  let year1 = monday.getFullYear()
  let month1 = monday.getMonth() + 1
  let day1 = monday.getDate()
  //结束时间的年月日
  let year2 = lastDay.getFullYear()
  let month2 = lastDay.getMonth() + 1
  let day2 = lastDay.getDate()
  //处理起始时间小于10的追加"0"在前面
  month1 = month1 < 10 ? '0' + month1 : month1
  day1 = day1 < 10 ? '0' + day1 : day1
  //处理结束时间小于10的追加"0"在前面
  month2 = month2 < 10 ? '0' + month2 : month2
  day2 = day2 < 10 ? '0' + day2 : day2

  returnArr[0] = year1 + '-' + month1 + '-' + day1 + ' 00:00:00' //起始时间
  returnArr[1] = year2 + '-' + month2 + '-' + day2 + ' 23:59:59' //结束时间
  return returnArr
}

/**
 * 小于0补0
 */
const addZero = function (num) {
  return num < 10 ? '0' + num : num
}

/**
 * 获取工作周
 */
const getWorkWeek = function () {
  const date = new Date()
  const week = date.getDay() //获取今天星期几
  // const saturday = getBeforeOrAfterDate(week + 8, 1, date)
  // const friday = getBeforeOrAfterDate(week + 2, 1, date)
  const monday = getBeforeOrAfterDate(week + 6, 1, date)
  const sunday = getBeforeOrAfterDate(week, 1, date)
  return {
    startTime: monday.getFullYear() +
      '-' +
      addZero(monday.getMonth() + 1) +
      '-' +
      addZero(monday.getDate()),
    endTime: sunday.getFullYear() +
      '-' +
      addZero(sunday.getMonth() + 1) +
      '-' +
      addZero(sunday.getDate())
  }
}

/**
 * 获取月的第一天
 */
const getMonthFirst = function (date) {
  date = date ? new Date(date) : new Date()
  date.setDate(1)
  return (
    date.getFullYear() +
    '-' +
    addZero(date.getMonth() + 1) +
    '-' +
    addZero(date.getDate())
  )
}

/**
 * 获取月的最后一天
 */
const getMonthLast = function (date) {
  date = date ? new Date(date) : new Date()
  let currentMonth = date.getMonth()
  const nextMonth = ++currentMonth
  const nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1)
  const oneDay = 1000 * 60 * 60 * 24
  const newDate = new Date(nextMonthFirstDay - oneDay)
  return (
    newDate.getFullYear() +
    '-' +
    addZero(newDate.getMonth() + 1) +
    '-' +
    addZero(newDate.getDate())
  )
}

/**
 * 时间差时间
 */
const dateDiff = function (date) {
  date = new Date(date)
  let now = new Date() // 获取当前时间
  let diff = now.getTime() - date.getTime() // 时间差的毫秒数

  // 计算相差天数
  let dayDiff = Math.floor(diff / (24 * 3600 * 1000)) // 计算出相差天数

  // 计算相差小时数
  let leave1 = diff % (24 * 3600 * 1000) // 计算天数后剩余的毫秒数
  let hours = Math.floor(leave1 / (3600 * 1000)) // 计算出小时数

  // 计算相差分钟数
  let leave2 = leave1 % (3600 * 1000) // 计算小时数后剩余的毫秒数
  let minutes = Math.floor(leave2 / (60 * 1000)) // 计算相差分钟数

  // 计算相差秒数
  let leave3 = leave2 % (60 * 1000) // 计算分钟数后剩余的毫秒数
  let seconds = Math.round(leave3 / 1000)
  let diffStr = ''

  if (dayDiff) {
    diffStr = dayDiff + '天前'
  } else if (!dayDiff && hours) {
    diffStr = hours + '小时前'
  } else if (!dayDiff && !hours && minutes) {
    diffStr = minutes + '分钟前'
  } else if (!dayDiff && !hours && !minutes && seconds) {
    diffStr = minutes + '秒前'
  }

  return diffStr
}

/**
 * 格式化时间
 * https://www.cnblogs.com/gwkzb/p/12603005.html
 */
const formatDate = function (date, fmt) {
  if (!date) return

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      )
    }
  }
  return fmt
}

/**
 * 补0
 */
const padLeftZero = function (str) {
  return ('00' + str).substr(str.length)
}

/**
 * 秒数格式化
 */
const formatSeconds = function (value) {
  if (!value) {
    return '0'
  }
  var secondTime = parseInt(value) // 秒
  var minuteTime = 0 // 分
  var hourTime = 0 // 小时
  if (secondTime > 60) {
    //如果秒数大于60，将秒数转换成整数
    //获取分钟，除以60取整数，得到整数分钟
    minuteTime = parseInt(secondTime / 60)
    //获取秒数，秒数取佘，得到整数秒数
    secondTime = parseInt(secondTime % 60)
    //如果分钟大于60，将分钟转换成小时
    if (minuteTime > 60) {
      //获取小时，获取分钟除以60，得到整数小时
      hourTime = parseInt(minuteTime / 60)
      //获取小时后取佘的分，获取分钟除以60取佘的分
      minuteTime = parseInt(minuteTime % 60)
    }
  }
  var result = '' + parseInt(secondTime) + '秒'

  if (minuteTime > 0) {
    result = '' + parseInt(minuteTime) + '分' + result
  }
  if (hourTime > 0) {
    result = '' + parseInt(hourTime) + '小时' + result
  }
  return result
}

// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// 日期、时间
const formatDateToDay = function (date) {
  // YYYY-MM-DD
  if (date) {
    if (typeof date === 'number') {
      date = new Date(date)
    }
    if (date instanceof Date) {
      let year = date.getFullYear().toString()
      let month = (date.getMonth() + 1).toString()
      if (month.length === 1) {
        month = '0' + month
      }
      let day = date.getDate().toString()
      if (day.length === 1) {
        day = '0' + day
      }
      // let hour = date.getHours().toString()
      // if (hour.length === 1) { hour = '0' + hour }
      // let minute = date.getMinutes().toString()
      // if (minute.length === 1) { minute = '0' + minute }
      // let second = date.getSeconds().toString()
      // if (second.length === 1) { second = '0' + second }

      let newDate = year + '-' + month + '-' + day
      return newDate
    } else {
      console.log('type error')
    }
  } else {
    return ''
  }
}

const formatDateToDayNum = function (date, type) {
  let numArr = ['日', '一', '二', '三', '四', '五', '六']
  let unit = type ? '星期' : '周'

  if (!date) return ''

  return unit + numArr[date.getDay()]
}

// 日期、时间
const formatDateToMonth = function (date) {
  // YYYY-MM
  if (date) {
    if (typeof date === 'number') {
      date = new Date(date)
    }
    if (typeof date === 'string') {
      date = new Date(date)
    }
    if (date instanceof Date) {
      let year = date.getFullYear().toString()
      let month = (date.getMonth() + 1).toString()
      if (month.length === 1) {
        month = '0' + month
      }
      // let day = date.getDate().toString()
      // if (day.length === 1) {
      //   day = '0' + day
      // }
      // let hour = date.getHours().toString()
      // if (hour.length === 1) { hour = '0' + hour }
      // let minute = date.getMinutes().toString()
      // if (minute.length === 1) { minute = '0' + minute }
      // let second = date.getSeconds().toString()
      // if (second.length === 1) { second = '0' + second }

      let newDate = year + '-' + month
      return newDate
    } else {
      console.log('type error')
    }
  } else {
    return ''
  }
}
const formatDateToMinute = function (date) {
  // YYYY-MM-DD
  if (date) {
    if (typeof date === 'number') {
      date = new Date(date)
    }
    let year = date.getFullYear().toString()
    let month = (date.getMonth() + 1).toString()
    if (month.length === 1) {
      month = '0' + month
    }
    let day = date.getDate().toString()
    if (day.length === 1) {
      day = '0' + day
    }
    let hour = date.getHours().toString()
    if (hour.length === 1) {
      hour = '0' + hour
    }
    let minute = date.getMinutes().toString()
    if (minute.length === 1) {
      minute = '0' + minute
    }
    // let second = date.getSeconds().toString()
    // if (second.length === 1) { second = '0' + second }

    let newDate = year + '-' + month + '-' + day + ' ' + hour + ':' + minute
    return newDate
  } else {
    return ''
  }
}

/**
 * 给大数字添加逗号分隔符
 * @param {number} value  待格式化的值
 * @returns {string} 格式化后的值
 * @runkit true
 * @example
 * formatLargeNumber(1000000);
 * // => '1,000,000'
 */
const formatLargeNumber = function (value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * 格式化金钱格式，默认是分
 * @param {number} value  待格式化的金额数字
 * @param {boolean} cent 是否是分
 * @returns {string} 格式化之后的金额，精度2位
 * @runkit true
 * @example
 * const { format } = require('happy-utils/money')
 * format(1000000, false));
 * //'1,000,000.00'
 * format(1000000);
 * //'10,000.00'
 */
const formatMoney = function (value) {
  const cent =
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true
  value = parseFloat(value)
  if (cent) {
    value /= 100
  }
  return formatLargeNumber(value.toFixed(2))
}


// ---------------------------------------------
// import getDictionary from './dictionary/get-dictionary'
// import fmtByDict from './dictionary/fmt-by-dict'
// import deepClone from './object/deep-clone'
// import request from './request/request'

// ---------------------------------------------
export default {
  hasClass, // 原生JS实现addClass, removeClass, toggleClass函数
  addClass,
  removeClass,
  toggleClass,
  createUUID, // 生成uuid
  nodelistToArray, // 将类数组转换成数组
  showLocalStorage, // handle localStorage
  removeLocalStorageItems,
  removeLocalStorageItemsExcept,
  getLocalHrefParam, // 获取地址栏参数方法
  ajax, // 原生js实现ajax方法
  formatterColChartData,
  formatterPieChartData,
  camelCaseToUpperCaseUnderline, // 将驼峰格式转换成大写下划线格式
  getBeforeOrAfterDate, // 获取传入的日期前N天或后N日期
  getLastWeekArray, // 获取上周的起始和结束时间
  getWorkWeek, // 获取工作周
  getMonthFirst, // 获取月的第一天
  getMonthLast, //获取月的最后一天
  dateDiff, // 时间差时间
  formatDate, // 格式化时间
  formatSeconds, // 秒数格式化

  formatDateToDay, // 日期、时间
  formatDateToDayNum, // 格式化星期|周
  formatDateToMonth, // 格式化日期--年月(YYYY-MM)
  formatDateToMinute,
  formatLargeNumber, // 给大数字添加逗号分隔符
  formatMoney, // 格式化金钱格式，默认是分
}
