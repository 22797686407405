import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: '/homepage'
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import("../views/login.vue"),
  },
  {
    path: "/homepage",
    name: "homepage",
    component: () =>
      import(/* webpackChunkName: "homepage" */ "../views/gateway/homepage.vue"),
  },
  {
    path: "/zyBrand",
    name: "zyBrand",
    component: () =>
      import(/* webpackChunkName: "gateway" */ "../views/gateway/zy-brand.vue"),
  },
  {
    path: "/industrialPark",
    name: "industrialPark",
    component: () =>
      import(/* webpackChunkName: "gateway" */ "../views/gateway/industrial-park.vue"),
  },
  {
    path: "/expertServices",
    name: "expertServices",
    component: () =>
      import(/* webpackChunkName: "gateway" */ "../views/gateway/expertServices.vue"),
  },
  {
    path: "/expertDetail",
    name: "expertDetail",
    component: () =>
      import(/* webpackChunkName: "gateway" */ "../views/gateway/expert-detail.vue"),
  },
  {
    path: "/parkService",
    name: "parkService",
    component: () =>
      import(/* webpackChunkName: "parkService" */ "../views/gateway/park-service.vue"),
  },
  {
    path: "/newsCenter",
    name: "newsCenter",
    component: () =>
      import(/* webpackChunkName: "gateway" */ "../views/gateway/news-center.vue"),
  },
  {
    path: "/projectCenter",
    name: "/projectCenter",
    component: () =>
      import(/* webpackChunkName: "parkService" */ "../views/gateway/project-center.vue")
  },
  {
    path: "/subPages",
    name: "subPages",
    component: () =>
      import(/* webpackChunkName: "parkService" */ "../views/gateway/sub-pages.vue"),
  },
  // {
  //   path: '/:path(.*)*',
  //   redirect: { name: 'homepage' },
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // console.log('scrollBehavior')
    document.querySelector('#app').scrollTop = to.meta.savedPosition || 0
  },
});

export default router;
